<template>
<div class="login">
  <div class="login-box">
    <div class="title">
      <img src="../assets/img/logo.png" />
      <span>重庆牧予科技有限公司</span>
    </div>
    <div class="input">
      <el-form :model="form" :rules="rules" ref="validform">
        <el-form-item prop="username">
          <el-input placeholder="工号" v-model.number="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input placeholder="密码" type="password" v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="login">登录</el-button>
    </div>
  </div>

  <!--  -->
  <div class="support">
    <span>重庆牧予科技有限公司</span>
    <img src="../assets/img/wh.png" />
    <span>技术支持：华有科技</span>
  </div>
</div>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  inject
} from "vue";
import {
  useRouter
} from 'vue-router'
const router = useRouter()

onMounted(() => {
  document.body.onkeydown = function(e) {
    if (e.keyCode == 13) {
      login()
    }
  }
})
const validform = ref(null);
const form = reactive({
  username: "",
  password: "",
  enterpriseId: 1005
});
const vm = inject("plugin");

// 登录
function login() {
  validform.value.validate((valid) => {
    if (valid) {
      vm.HttpPost("/admin/login", form).then((res) => {
        if (res.data && res.code == 0) {
          res.data.user.avatar = res.data.user.headerImg;
          window.localStorage.setItem('userInfo', JSON.stringify(res.data.user));
          window.localStorage.setItem('token', res.data.token)
          router.push('nav')
        }
      });
    }
  });
}

const rules = {
  username: [{
      required: true,
      message: "请输入工号",
      trigger: "blur"
    },
    {
      required: true,
      message: "请输入正确工号",
      trigger: "blur",
      type: "number",
    },
  ],
  password: [{
    required: true,
    message: "请输入密码",
    trigger: "blur"
  }],
};
</script>
<style lang="scss" scoped>
.login {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url("../assets/img/loginbg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .login-box {
        width: 520px;
        height: 420px;
        background: #ffffff;
        border: 1px solid #c8c8c8;
        // padding: 40px 70px 80px 70px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 221px;
        transform: translateY(-50%);
        .title {
            width: 100%;
            height: 88px;
            border-bottom: 1px solid #efefef;
            display: flex;
            align-items: center;
            padding: 20px 70px 0;
            box-sizing: border-box;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            img {
                width: 80px;
                height: 39px;
                margin-right: 20px;
            }
        }
        .input {
            width: 100%;
            padding: 60px 70px;
            box-sizing: border-box;
            .el-input {
                height: 42px;
                border-radius: 0;
                /deep/ .el-input__inner {
                    height: 100%;
                    border-radius: 0;
                }
            }
        }
        .btn {
            width: 100%;
            height: 42px;
            border-radius: 0;
            background: #3d7eff;
            margin-top: 30px;
        }
    }
    .support {
        position: fixed;
        bottom: 79px;
        right: 260px;
        img {
            width: 10px;
            height: 11px;
            margin: 0 5px 0 16px;
        }
        span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            &:nth-child(1) {
                font-weight: 400;
                color: #666666;
            }
            &:nth-child(3) {
                font-weight: 500;
                color: #999999;
            }
        }
    }
}
</style>
